<template>
  <div class="page">
    <page-title title="提现" />
    <div class="content">
      <div class="form">
        <div class="tip">
          <i
            class="el-icon-warning"
            style="color: #1890ff"
          />确认提现后，佣金将直接打入微信账户中。
        </div>
        <div class="item">
          <span class="label">推广人姓名：</span
          ><span class="value">{{ name }}</span>
        </div>
        <div class="item">
          <span class="label">推广人手机号：</span
          ><span class="value">{{ phone }}</span>
        </div>
        <div class="item">
          <span class="label">累计获得佣金：</span
          ><span class="number">{{ total_amount }}</span>
        </div>
        <div class="item">
          <span class="label">已提现佣金金额：</span
          ><span class="number">{{ earn_amount }}</span>
        </div>
        <div class="line" />
        <div class="item">
          <span class="label">可提现金额：</span
          ><span class="number">{{ current_amount }}</span>
        </div>
        <div class="item">
          <span class="lable">提现金额：</span>
          <el-input
            v-model="number"
            placeholder="请输入"
            class="input"
            type="number"
          />
        </div>
        <div class="item">
          <el-button type="primary" style="margin-left: 70px" @click="submit"
            >提交</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../components/PageTitle.vue";
export default {
  components: { PageTitle },

  data() {
    return {
      name: "",
      phone: "",
      total_amount: "", //累计
      earn_amount: "", //已提现金额
      current_amount: "", //可提现
      number: "",
    };
  },

  computed: {},

  watch: {},

  methods: {
    async requestDetail() {
      let url = "admin/seller/detail";
      const data = await this.$https.get(url);
      if (data) {
        this.name = data.nick_name;
        this.phone = data.phone;
        this.total_amount = this.fmoney(data.total_amount);
        this.earn_amount = this.fmoney(data.earn_amount);
        this.current_amount = this.fmoney(data.current_amount);
      }
    },
    async submit() {
      if (!this.number) {
        this.$message({
          type: "error",
          message: "请输入提现金额",
        });
        return;
      }
      let params = {
        apply_amount: Number(this.number) * 100,
      };
      let url = "admin/seller/apply/commit";
      const data = await this.$https.post(url, params);
      if (data) {
        if (data) {
          this.$message({
            type: "success",
            message: "提现成功",
          });
          this.requestDetail();
        } else {
          this.$message.error(data.msg ? data.msg : '提交失败')
        }
      }
    },
    fmoney(s) {
      return (Number(s) / 100).toLocaleString("en-US");
    },
  },
  mounted() {
    this.requestDetail();
  },
};
</script>
<style lang='scss' scoped>
.page {
  display: flex;
  flex-direction: column;
  .content {
    padding: 24px;
    display: flex;
    flex-direction: column;
    .form {
      align-self: center;
      width: 509px;
      display: flex;
      flex-direction: column;
      .tip {
        width: 509px;
        padding: 10px 16px;
        background: #e6f7ff;
        border: 1px solid #91d5ff;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
      }
      .item {
        margin-top: 24px;
        margin-left: 14px;
      }
      .line {
        margin-top: 24px;
        background-color: #e9e9e9;
        height: 1px;
      }
      .label {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
      }
      .value {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 22px;
      }
      .number {
        font-size: 20px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.65);
        line-height: 28px;
      }
      .input {
        width: 340px;
      }
    }
  }
}
</style>